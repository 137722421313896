.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
}

.about-video {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-video video {
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
  transition: opacity 0.5s; /* Added transition to the video */
}

.about-content {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 4rem;
  text-align: right;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.5s;
}

.about-text {
  font-size: 1.5rem;
  font-family: 'regular-mori';
  color: white;
}

.visible {
  opacity: 1;
}


@media screen and (max-width: 768px) {
  .about-section {
    height: 85%;
  }

  .about-text {
    font-size: 1rem;
  }

  .about-content {
    width: 85%;
  }

  .about-video {
    width: 100%;
  }
}
