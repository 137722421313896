.works {
  position: relative; 
  z-index: 1; 
}

.works-div {
  z-index: 5; /* Adjust as per your requirements */
}

.interceptor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; /* Adjust as necessary, ensuring it's above other elements but below the custom cursor */
  pointer-events: auto; /* By default, we don't want it capturing clicks */
}



.projects-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100vh;
  color: white;
}

.background-image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  opacity: 0;
  transition: opacity 1s ease, z-index 0.3s;
}

.background-image.active {
  z-index: 1; 
  opacity: 1;
}

.project-titles {
  padding-bottom: 20rem;
  z-index: 2;
}

.project, .project a {
  padding-left: 2rem;
  font-size: 0.8rem;
  color: white;
  font-family: 'regular-mori';
  margin-bottom: 0.8rem;
  opacity: 0.3;
  transition: opacity 0.3s ease;
  text-decoration: none;
}

.project.active, .project.active a {
  opacity: 1;
}

.custom-cursor {
  position: absolute;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: .7rem;
  font-family: 'regular';
  pointer-events: none;
  z-index: 9999;
  cursor: none;
}

.project-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlay-columns {
  display: flex;
  justify-content: space-between;
}

.overlay-images img {
  max-width: 100%;
  height: auto;
}

.overlay-texts {
  font-size: 1rem;
  font-family: 'regular';
}

.open-mobile {
  display: none;
}

@media screen and (max-width: 768px) {

  .interceptor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20; /* Adjust as necessary, ensuring it's above other elements but below the custom cursor */
    pointer-events: auto; /* By default, we don't want it capturing clicks */
}


  
  .project-titles {
    z-index: 3;
    padding-bottom: 18rem;
    padding-left: 0;
    pointer-events: none;
  }

  .works-div {
    z-index: 1; /* Adjust as per your requirements */
  }

  .custom-cursor {
    display: none;
    pointer-events: none;
  }

  .open-mobile {
    z-index: 3;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    font-family: 'regular';
    font-size: .8rem;
    bottom: 5rem;
    width: 100%; 
    animation: flashing 3s linear infinite;
  }
  
  
  .project-titles .project {
    pointer-events: all;
  }
  
  .project-titles .project a {
    pointer-events: all;
  }
  

  @keyframes flashing {
    0%, 100% {
      opacity: 0.1;
    }
    50% {
      opacity: .8;
    }
  }
}
